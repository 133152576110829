<template>
  <v-container fluid>
    <license-table :data="data" :loading="loading" />
  </v-container>
</template>

<script>
import { getLicense } from "@/api/system/license";
import licenseTable from "@/views/system/license/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    licenseTable,
  },
  data() {
    return {
      data: {
        allowedparts: {},
      },
      loading: true,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "systemLicense") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getLicense()
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
