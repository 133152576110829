<template>
  <v-row>
    <v-col cols="12" class="py-1 px-0">
      <v-simple-table
        hide-default-footer
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table version"
        :class="{'progress-linear': loading}"
      >
      <template v-if="loading">
        <v-progress-linear
        class="mt-0"
        indeterminate
        />
        <tbody class="loading-text">
            <tr>
              <td>Loading data from API...please wait</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td>{{ $t("system.license.License") }}:</td>
              <td>{{ data.lic_key }}</td>
            </tr>
            <tr>
              <td>{{ $t("system.license.Name") }}:</td>
              <td>{{ data.lic_name }}</td>
            </tr>
            <tr>
              <td>{{ $t("system.license.Type") }}:</td>
              <td>{{ data.lic_type }}</td>
            </tr>
            <tr>
              <td>{{ $t("system.license.Trial") }}:</td>
              <td>{{ data.lic_trial }}</td>
            </tr>
            <tr>
              <td>{{ $t("system.license.Expiry") }}:</td>
              <td>{{ data.lic_due_date }}</td>
            </tr>
            <tr>
              <td>{{ $t("system.license.Key") }}:</td>
              <td class="text-break">{{ data.app_key }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    data: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.progress-linear {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.system-container {
.v-data-table__wrapper {
  border-radius: 0px;
}
}
.theme--dark{
  .loading-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.5)
  }
}
.theme--light {
  .loading-text{
    text-align: center;
    color: rgba(0,0,0,.38);
  }
}
</style>
